<template>
  <el-card class="page-card">
    <UploadImage slot="header" @onsuccess="handleImageUploadSuccess" />
    <material-list
      ref="list"
      :type="type"
      :count="count"
      :pageSize="24"
      @onCheck="handleCheck"
    />
    <el-divider></el-divider>
    <el-row type="flex" justify="center">
      <el-popover
        placement="top"
        width="400"
        v-model="visible"
        style="margin-right: 10px"
      >
        <div style="margin: 8px">
          <el-select
            v-model="categoryid"
            :placeholder="this.$t('resident.pleaseSelect')"
            style="margin-right: 8px; width: 300px; display: inline-block"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :value="item.id"
              :label="item.title"
              >{{ item.title }}</el-option
            >
          </el-select>
          <el-button type="primary" @click="move">
            {{ $t("common.move") }}
          </el-button>
          <el-button type="text" @click="navto('/material/category')">
            {{ $t("common.edit") }}
            {{ $t("menu.material_category") }}
          </el-button>
        </div>
        <el-button
          type="primary"
          :disabled="list.length === 0"
          slot="reference"
        >
          {{ $t("content.category.movetocategory") }}
        </el-button>
      </el-popover>
      <el-popconfirm :title="$t('common.confirmDelete')" @confirm="del">
        <el-button type="danger" :disabled="list.length === 0" slot="reference">
          {{ $t("common.delete") }}
        </el-button>
      </el-popconfirm>
    </el-row>
  </el-card>
</template>
<script>
import { createData } from "@/helper/api.js";
import MaterialList from "@/components/MaterialList";
import UploadImage from "@/components/UploadImage";

export default {
  components: {
    MaterialList,
    UploadImage,
  },
  data() {
    return {
      categoryList: [],
      list: [],
      visible: false,
      type: "image",
      count: 0,
      categoryid: "",
    };
  },
  methods: {
    handleCheck(list) {
      this.categoryList = this.$refs["list"].$refs["selector"].list;
      this.list = list;
      // console.log("handleCheck-list:", list);
    },
    handleImageUploadSuccess(e) {
      console.log("handleImageUploadSuccess:", e);
      this.$refs["list"].getList(1);
    },
    move() {
      createData("POST", `oss/update-lot-categoryid`, {
        categoryId: this.categoryid,
        idList: this.list.map((v) => v._id),
      }).then((res) => {
        console.log("update-lot-categoryid-res:", res);
        this.$message({
          type: "success",
          message: this.$t("common.updateSuccess"),
        });
        this.visible = false;
        this.$refs["list"].cleanList();
        this.$refs["list"].getList();
      });
    },
    del() {
      createData("POST", `oss/update-lot-status`, {
        idList: this.list.map((v) => v._id),
      }).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("common.deleteSuccess"),
        });
        this.$refs["list"].cleanList();
        this.$refs["list"].getList();
        console.log("update-lot-status-res:", res);
      });
    },
    navto(path) {
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss">
.page-card {
  .el-upload-dragger {
    width: 100%;
    height: 180px;
  }

  .upload-oss {
    display: block;
  }

  .upload-oss {
    width: 100%;
  }
  .upload-oss .el-upload-dragger {
    width: 100%;
    height: 180px;
    margin: 0;
  }
  .upload-oss .el-upload {
    width: 100%;
    height: 180px;
  }
}
</style>